import React from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';

import Paragraph from '../../../ui/components/Paragraph';
import lang from '../../../lang/pages/index.lang';
import {VideoWrapper, VideoEmbed} from './styles';

const VideoPlayer = ({src, inLightbox}) => {
    return (
        <>
            <VideoWrapper inLightbox={inLightbox}>
                <VideoEmbed className="cookieconsent-optin-marketing">
                    <ReactPlayer
                        config={{
                            youtube: {
                                preload: true,
                                autoplay: 0,
                                playsinline: 1,
                                showinfo: 0,
                                rel: 0,
                                controls: 1,
                                allow: 'fullscreen',
                                allowFullScreen: 1,
                                mozAllowFullScreen: 'mozallowfullscreen',
                                msAllowFullScreen: 'msallowfullscreen',
                                oAllowFullScreen: 'oallowfullscreen',
                                webkitAllowFullScreen: 'webkitallowfullscreen',
                                fs: 1,
                                frameBorder: 0,
                                playerVars: {showinfo: 0, rel: 0},
                            },
                        }}
                        controls={true}
                        url={src}
                        width="100%"
                        height="100%"
                    />
                </VideoEmbed>
                <Paragraph className="cookieconsent-optout-marketing CookieDeclaration">
                    {lang.cookieAlert}
                </Paragraph>
            </VideoWrapper>
        </>
    );
};

VideoPlayer.propTypes = {
    src: PropTypes.string.isRequired,
    inLightbox: PropTypes.bool,
};

VideoPlayer.defaultProps = {
    inLightbox: false,
};

export default VideoPlayer;
