import styled, {css} from 'styled-components';

import {sm} from '../../../styles/media';
import {color} from '../../../styles/variables';

const lightboxStyles = css`
    margin: 0 auto;
    width: min(95vw, 1000px);
    height: max(70vh, 280px);

    ${sm`
        padding: 2rem;
    `}
`;

export const VideoWrapper = styled.div`
    background-color: ${color.lightBlue};
    display: flex;
    align-items: center;
    text-align: center;
    height: min(calc(100vh - 80px), 500px);
    ${(props) => (props.inLightbox ? lightboxStyles : null)}

    p {
        padding: 0 0.5rem;
    }
`;

export const VideoEmbed = styled.div`
    width: 100%;
    height: 100%;
    backface-visibility: hidden;

    iframe {
        border: 0;
        border-radius: 5px;
    }
`;
