/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

export default {
    apothekentrainingFuerDich: (
        <>
            Apotique – <strong>Apotheken&shy;training für Dich</strong> gemacht
        </>
    ),
    paragraph1: (
        <>
            Richte Dir Deine Apotique nach Deinem Geschmack ein und sammle
            wertvolle Prämienpunkte – mit Deinen Must&#8209;Have&#8209;Themen
            und Lieblingstrainings!
        </>
    ),
    button: 'Jetzt anmelden!',
    button2: 'Video abspielen',
    videoUrl: 'https://www.youtube.com/watch?v=QgyRbA5YtEU',
    img1:
        'Grafik:  Saisonale Specials in der Apotique. Apothekerin steht vor Regal.',
    section1headline: (
        <>
            Passend zur <strong>Jahreszeit</strong>
        </>
    ),
    section1paragraph:
        'Lasse Dich von saisonalen Specials inspirieren und bring Deinen eigenen Style ein – mit unserer maßgeschneiderten Apothekenausstattung.',
    section1link: 'Was ist die Apotique?',
    img2:
        'Grafik:  Trainings und Außendienst-Services der Apotique. Apothekerin im Beratungsgespräch.',
    section2headline: (
        <>
            In Deiner <strong>Apotheke</strong>
        </>
    ),
    section2paragraph: (
        <>
            Profitiere von exklusiven Außendienst&#8209;Services und stärke
            Deine persönliche Beratungsleistung – gegen feinste Prämien für Dein
            Apotheken-Team.
        </>
    ),
    section2link: 'Trainingskollektion anzeigen',
    img3:
        'Grafik:  Apothekentraining mit tollen Prämien. Apothekerin trägt Pakete.',
    section3headline: (
        <>
            Mitmachen <strong>lohnt sich</strong>
        </>
    ),
    section3paragraph:
        'Löse ganz einfach Deine gesammelten Punkte in unserem Prämienshop ein – z. B. gegen Sachprämien oder Wertgutscheine.',
    section3link: 'Zum Prämienshop',
    schaufensterHeadline: (
        <>
            Lust auf einen <strong>Trainings-Schaufenster&shy;bummel?</strong>
        </>
    ),
    schaufensterParagraph: (
        <>
            Suche Dir etwas aus unserer breit gefächerten Trainingskollektion
            aus und sichere Dir Prämienpunkte: Von Casual Know&#8209;How bis
            Luxus&#8209;Wissen ist alles dabei!
        </>
    ),
    headerAlt:
        'Grafik:  Maßgeschneiderte eLearnings für Apotheker. Apothekerin zeigt Trainings.',
    cookieAlert: (
        <>
            Du hast das Abspielen von Videos in Deinen Cookie-Einstellungen
            nicht gestattet. Wenn Du Dir das Video anschauen möchtest, stimme
            bitte <a href="javascript: Cookiebot.renew()">hier</a> den Marketing
            Cookies zu.
        </>
    ),
};
